import request from "graphql-request";
import React, { useEffect, useState } from "react";
import "../assets/css/websites.scss";
import ContactUs from "../components/contactUs";
import Head from "../components/head";
import Layout from "../components/layout";
import Loading from "../components/loading";
import ShowProvider from "../components/showProvider";
import SingleRealizationCard from "../components/singleRealizationCard";

function WebsitePage({ pageContext }) {
  // eslint-disable-next-line
  const strapiHost = process.env.GATSBY_API_URL;
  const [realizations, setRealizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{ 
            website(locale: "${pageContext.intl.language}") {
              pageTitle
              title
              title2
              title_section3
              title_section2
              description
              img_section2 { 
                url
              }
              description_section2
              img {
                url 
              }
              img_section2 {
                url
              }
            }
              realizations (limit: 3,locale: "${pageContext.intl.language}",where:{realization_category:{slug:"website"}}) {
                title
                logo{
                  url
                }
                thumbnailOptionalLogo{
                  url
                }
                slug
          }
        }
        `
      );
      return res;
    };
    fetch().then((items) => {
      setRealizations(items.realizations);
      setData(items.website);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <Layout initial={false} nav={false} contactUs={false}>
            <div className="website__section1">
              <div className="website__section1-left">
                <h1 className="website__section1-title">{data.title}</h1>
                <p className="website__section1-desc">{data.description}</p>
              </div>
              <div className="website__section1-right">
                <img
                  src={`${strapiHost}${data.img.url}`}
                  className="websites__img"
                />
              </div>
            </div>
          </Layout>
          <Layout nav={false} initial={false} contactUs={false}>
            <div className="website__section2">
              <div className="website__section2-left">
                <img src={`${strapiHost}${data.img.url}`} />
              </div>
              <div className="website__section2-right">
                <h2 className="website__section2-title">
                  {data.title_section2}
                </h2>
                <p className="website__section2-description">
                  {data.description_section2}
                </p>
              </div>
            </div>
            <div className="website__realizations">
              <h2 className="website__realizations-title">
                {data.title_section3}
              </h2>
              <div className="website__realizations-content">
                {realizations.map(
                  ({ logo, slug, title, thumbnailOptionalLogo }, key) => (
                    <SingleRealizationCard
                      key={key}
                      title={title}
                      bg={"rgb(248, 249, 252)"}
                      img={
                        thumbnailOptionalLogo
                          ? thumbnailOptionalLogo.url
                          : logo.url
                      }
                      to={slug}
                    />
                  )
                )}
              </div>
            </div>
          </Layout>
        </ShowProvider>
      )}
      <ContactUs />
    </>
  );
}

export default WebsitePage;
